<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'bad-sanierung-taunusstein',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/bad-sanierung-taunusstein',
      imageCount: 12,
      imageFileFormat: '.jpg',
      name: 'Kernsanierung eines Bades',
      description:
        'Alte Sanitärobjekte wurden demontiert und die Wandfliesen abgeschlagen. Die Dusche wurde umgebaut. Alle Wände wurden raumhoch gefliest und die Bodenfliesen verlegt. Eine neue weiße Tür, sowie Einbaustrahler in der Decke, die glatt gespachtelt und mit geeigneter Farbe gestrichen wurde, runden das Gesamtbild ab.',
    },
    //
  }),
};
</script>
